import UIkit from 'uikit';
import IMask from 'imask';
// Эта штука для обрабоки картинок
import.meta.glob([
    '../assets/images/**',
    '../assets/svg/**'
]);

// PHONE MASK
const maskedEl = document.getElementById('telInput');
const maskOptions = {
    mask: '+38(000)-000-00-00'
};
window.addEventListener('load', () => {
    if (maskedEl) {
        const mask = IMask(maskedEl, maskOptions);
    }
    initTeamSlider();

})

function initTeamSlider() {
    const slider = document.getElementById('team__slider');
    let sliderUI
    if (slider) {
        if (window.innerWidth <= 768) {
            sliderUI = UIkit.slider(slider, {
                autoplay: true,
                finite: true,
                center: true
            });
        } else {
            UIkit.slider(slider).$destroy();
        }
    }
}

window.addEventListener('resize', () => {
    initTeamSlider();
})

window.showThanksPopup = () => {
    event.preventDefault();
    let modal = document.getElementById('thanks_modal');
    if (modal) {
        UIkit.modal(modal).show();
    }
}

const vh = window.innerHeight / 100;
document.documentElement.style.setProperty('--vh', `${vh}px`);
